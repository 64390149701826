import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { SPACE_SETTINGS_LANDING_PAGE } from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import {
	isForgeAppRoute,
	isSPASettingsRoute,
	isSpacePermissionsRoute,
	isSpaceMembersRoute,
} from '../helpers/webItem';

import { SettingsIcon } from './img/SettingsIcon';

const i18n = defineMessages({
	generalSpaceSettingsLink: {
		id: 'space-settings.sidebar-links.space-settings.link',
		defaultMessage: 'Space settings',
		description: 'Space Settings Menu Item',
	},
	spaceSettingsTooltip: {
		id: 'space-settings.sidebar-links.space-settings.tooltip',
		defaultMessage: 'View settings for this space',
		description: 'Tooltip message on hover of space settings space link',
	},
});

const ANALYTICS_SOURCE = 'spaceSettingsV2SideNavLink';

export type SpaceSettingsSidebarLinksProps = {
	spaceKey?: string;
	isSpaceSettingsScreen: boolean;
	isSideNavRefreshAutomationEnabled?: boolean;
	cssFn?: any;
};

export const SpaceSettingsSidebarLinks = ({
	spaceKey,
	isSpaceSettingsScreen,
	cssFn,
	isSideNavRefreshAutomationEnabled = false,
}: SpaceSettingsSidebarLinksProps) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { location, matchRoute } = useContext(RoutesContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const trackClickAnalytics = (clickedLinkId: string | null) =>
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'spaceSettingsSidebarLink',
				source: ANALYTICS_SOURCE,
				attributes: {
					linkId: clickedLinkId,
				},
			},
		}).fire();

	const isForgeAppPage = isForgeAppRoute(matchRoute, location);
	const isSPASettingsPage = isSPASettingsRoute(
		matchRoute,
		location,
		isSideNavRefreshAutomationEnabled,
	);
	const isEitherSpacePermisisonsPage =
		isSpacePermissionsRoute(matchRoute, location) || isSpaceMembersRoute(matchRoute, location);
	const showSettingsItems =
		isSpaceSettingsScreen || isForgeAppPage || isSPASettingsPage || isEitherSpacePermisisonsPage;

	return (
		<>
			<Tooltip position="top" content={<FormattedMessage {...i18n.spaceSettingsTooltip} />}>
				{(tooltipProps) => (
					<SpotlightTarget name="live-pages-changeboarding.space-settings">
						<li>
							<LinkItem
								// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
								cssFn={cssFn}
								key="space-settings"
								iconBefore={<SettingsIcon isSelected={showSettingsItems} />}
								// in the future this will be true when user is on the main
								// space settings overview page with all the card links
								isSelected={showSettingsItems}
								href={SPACE_SETTINGS_LANDING_PAGE.toUrl({ spaceKey })}
								{...tooltipProps}
								onClick={() => trackClickAnalytics('space-settings')}
								testId={`space-navigation-space-settings-v2${showSettingsItems ? '-selected' : ''}`}
								data-vc="space-settings-sidebar-link"
								{...ssrPlaceholderIdProp}
							>
								<FormattedMessage {...i18n.generalSpaceSettingsLink} />
							</LinkItem>
						</li>
					</SpotlightTarget>
				)}
			</Tooltip>
		</>
	);
};
